import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Stack } from '@mui/material';
import StoreContext from '../../store/store';


export default function Header({ toggleDrawer }) {
    const {_state}=React.useContext(StoreContext);
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 2, display: { sm: 'flex', md: 'none' } }}
                        onClick={toggleDrawer}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                    >
                        Dashboard
                    </Typography>
                    <Box sx={{ marginLeft: 'auto' }}>
                        <Stack
                            direction="row"
                            alignItems="center"
                        >
                            <IconButton
                                size="large"
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            <p>{_state.user.name}</p>
                        </Stack>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
