import React, { useState } from "react";
import {
    Button,
    CircularProgress,
} from "@mui/material";

import { InputField, PasswordInputField } from "../../components";
import axios from "axios";

export default function ResetPassword({_url,gotoFirstStep,_header,showAlertMessage, email}) {
    console.log("🚀🚀🚀 -------------------------------------------------------------------🚀🚀🚀");
    console.log("🚀🚀🚀 ~ file: reset-password.js:11 ~ ResetPassword ~ email:", email);
    console.log("🚀🚀🚀 -------------------------------------------------------------------🚀🚀🚀");
    const [formData, setFormData] = useState({
        email,
        password: "",
        token: "",
        showPassword: false,
    });
    const [formErrors, setFormErrors] = useState({
        token: "",
        password: "",
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleUpdate = (e) => {
        e.preventDefault();
        setIsLoading(true);
        let _data = {
            resetToken: formData.token,
            password: formData.password,
            confirmPassword: formData.password,
            email
        };

        axios
            .post(_url + "reset-password", _data, _header)
            .then((_json) => {
                setIsLoading(false);
                gotoFirstStep();
                showAlertMessage(_json.data.message);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response.status === 400) {
                    let msg = err.response.data.error.message;
                    showAlertMessage(msg);
                }
                if (err.response.status === 422) {
                    let msg = err.response.data.detail.token[0];
                    setFormErrors({ ...formErrors, token: msg });
                }
            });
    };

    return (
        <form onSubmit={handleUpdate} autoComplete="off">
            <InputField
                label="Token"
                name="token"
                error={formErrors.token}
                handleChange={(event) => handleChange(event)}
                required
                placeholder="xxxxxx"
                styles={{ mb: 2 }}
            />
            <PasswordInputField
                label="Password"
                name="password"
                placeholder="password"
                required
                handleChange={(event) => handleChange(event)}
            />
            <div style={{ textAlign: "center" }}>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={isLoading}
                    sx={{ marginTop: "3rem", textTransform: "capitalize" }}
                >
                    {isLoading ? (
                        <CircularProgress
                            size={15}
                            sx={{ color: "#fff", marginRight: 1 }}
                        />
                    ) : null}
                    Submit
                </Button>
            </div>
        </form>
    );
}
