import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "../layout";
import { Home, SignIn } from "../screens";
import { SplashScreen } from "../components";
import StoreContext from "../store/store";
import API from "../axios";

const myRoutes = [
    {
        path: "/sign-in",
        component: <SignIn />,
        protected: false,
        layout: false,
    },
    {
        path: "/reset/password/:token",
        component: <SignIn />,
        protected: false,
        layout: false,
    },
    {
        path: "/",
        component: <Home />,
        protected: true,
        layout: true,
    }
];

export default function Router() {
    const [isLoading, setIsLoading] = useState(true);
    const { _state, _dispatch } = useContext(StoreContext);
    const _token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN);

    useEffect(() => {
        if (!!_token) {
            getUser();
            return
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, []);

    const getUser = () => {
        API('get', 'me').then(_json => {
            _dispatch({
                type: "LOGIN",
                payload: {
                    user: _json.data.detail,
                    token: _token,
                },
            });
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);
        })
    }

    return (
        <>
            {isLoading ? (
                <SplashScreen />
            ) : (
                <Routes>
                    {myRoutes.map((_v, _i) => {
                        return (
                            <Route
                                key={_i}
                                path={_v.path}
                                exact
                                element={<Protected isLogged={_state.isLogged} ele={_v} children={_v.component} />}
                            />
                        );
                    })}
                </Routes>
            )}
        </>
    );
}

const Protected = ({ isLogged, ele, children }) => {
    console.log("🚀 ~ file: router.js ~ line 84 ~ Protected ~ isLogged", isLogged)

    if (!isLogged && ele.protected) {
        console.log("🚀 ~ protected", isLogged)
        return <Navigate to="/sign-in" replace />;
    }

    return (
        <>
            {ele.layout ? <Layout>{children}</Layout> : <>{children}</>}
        </>
    );
}