import React, { useState, useEffect } from "react";
import { Box, Container, CircularProgress, Grid, Paper, Typography, Button, Snackbar, Alert } from "@mui/material";

import PageTitle from "../../hooks/page-title";
import API from "../../axios";

export default function Home() {
  PageTitle('Home');

  const [record, setRecord] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ open: false, message: "Error!", type: 'warning' });


  const markAttendance = () => {
    setIsLoading(true);
    API("post", 'mark-attendance')
      .then((_json) => {
        setRecord(_json.data);
        setIsLoading(false);
        setAlert({ open: true, message: 'Attendance Mark Successfully', type:'success'});
      })
      .catch((err) => {
        setIsLoading(false);
        if(err?.response?.data?.message) {
          setAlert({ open: true, message: err?.response?.data?.message, type:'warning'});
        } else {
          setAlert({ open: true, message: 'Attendance not marked', type:'warning'});
        }
      });
  };

  return (
    <Container maxWidth="false" sx={{ pt: 3, pb: 3 }} >
      <Grid container spacing={2}>

        <Grid item sm={6} xs={12}>
        <Button variant="contained" color="primary" onClick={markAttendance}>
          Mark Attendance
        </Button>
        </Grid>

      </Grid>
      {
        alert.open &&
        <Snackbar open={alert.open} autoHideDuration={3000} onClose={() => {}}>
          <Alert onClose={() => {}} severity={alert.type} sx={{ width: "100%" }}>
            {alert.message}
          </Alert>
        </Snackbar>
      }
    </Container>
  );
}
